import React, { useState, useEffect } from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Cta from "../components/cta";
import Hero from "../components/hero";

import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import styled from "styled-components";
const Box = styled.div`
    .box,
    .link {
        transition: 250ms cubic-bezier(0.4, 0, 0.2, 1);
    }
    .box:hover {
        box-shadow: 0px 4px 16px rgb(46 41 51 / 8%),
            0px 8px 24px rgb(71 63 79 / 16%);
    }
    .box:hover .link {
        opacity: 1;
    }
    .link {
        opacity: 0;
    }
`;

function Integration({ data }) {
    let d = data.contentfulPageIntegration;

    const portfolio = d.content;

    const [filter, setFilter] = useState("semua");
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        setProjects(portfolio);
    }, []);

    useEffect(() => {
        setProjects([]);

        const filtered = portfolio
            .sort((a, b) => (a.icon > b.icon ? 1 : -1))
            .map((p) => ({
                ...p,
                filtered: p.category.includes(filter),
            }));
        setProjects(filtered);
    }, [filter]);

    return (
        <Layout>
            <SEO
                keywords={d.seo.keywords}
                title={d.seo.title}
                description={d.seo.description}
            />

            <Hero
                props={{
                    label: d.label,
                    title: d.title,
                    desc: renderRichText(d.subtitle),
                    style: {
                        backgroundRepeat: `no-repeat`,
                        backgroundPosition: `20% 100%`,
                    },
                }}
            />

            <section>
                <div className="w-full max-w-6xl mx-auto px-4 py-8 md:px-8 md:py-16 flex space-x-8">
                    <div className="w-1/4 flex flex-col items-start">
                        <h4 className="font-display mb-4 leading-none">
                            Kategori
                        </h4>
                        {d.categories.map((item) => (
                            <button
                                type="button"
                                className={`text-left text-sm md:text-md px-3 py-1 border border-transparent w-auto inline-block rounded-full mb-2 capitalize bg-gray-50 ${
                                    filter === item.name
                                        ? `text-primary  border-primary bg-opacity-10 bg-primary`
                                        : `text-gray-700 dark:text-gray-300 dark:bg-gray-800`
                                }`}
                                key={item.name}
                                onClick={() => setFilter(item.name)}
                            >
                                {item.name}
                            </button>
                        ))}
                    </div>
                    <div className="w-3/4">
                        <h2 className="font-display leading-tight mb-6">
                            {
                                d.categories.find(function (i) {
                                    return i.name === filter;
                                }).desc
                            }
                        </h2>

                        <div className="grid gap-x-4 gap-y-4 grid-cols-1 md:grid-cols-3">
                            {projects.map((item) =>
                                item.filtered === true ? (
                                    <Box key={item.name}>
                                        <div className="rounded-lg h-full p-4 md:p-6 box bg-gray-50 dark:bg-gray-700 cursor-pointer">
                                            <div className="flex justify-between">
                                                <img
                                                    className="mb-4 md:mb-6"
                                                    src={`../icons/brands/${item.icon}`}
                                                    alt={item.name}
                                                    style={{
                                                        width: `auto`,
                                                        height: `auto`,
                                                        maxWidth: `100px`,
                                                        maxHeight: `28px`,
                                                    }}
                                                />
                                                <a
                                                    href={item.url}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="link w-4 h-4"
                                                >
                                                    <img
                                                        src="../icons/external-link.svg"
                                                        alt="open"
                                                    />
                                                </a>
                                            </div>

                                            <p className="text-sm md:text-normal text-gray-500 dark:text-gray-300 leading-tight">
                                                {item.desc}
                                            </p>
                                        </div>
                                    </Box>
                                ) : (
                                    ""
                                )
                            )}
                        </div>

                        <div className="w-full p-8 bg-green-100 dark:bg-gray-600 text-green-900 dark:text-gray-200 mt-8 rounded-lg shadow-sm font-sans">
                            {renderRichText(d.cta)}
                        </div>
                    </div>
                </div>
            </section>

            <Cta />
        </Layout>
    );
}

Integration.propTypes = {
    data: PropTypes.object,
};

export const query = graphql`
    {
        contentfulPageIntegration {
            seo {
                title
                description
                keywords
            }
            label
            title
            subtitle {
                raw
            }
            categories {
                name
                desc
            }
            content {
                name
                icon
                category
                desc
                url
            }
            cta {
                raw
            }
        }
    }
`;

export default Integration;
